// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")

require("jquery") // Don't really need to require this...
require("jquery-ui")

$(function(){
    // Plain jquery
    //$('#tags').fadeOut(5000);
  
    // jquery-ui
    const availableCities = ['Baltimore', 'New York'];
    $('#tags').autocomplete( { source: availableCities } );
  })


require("bootstrap")

//import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
//require("stylesheets/application.scss")

/*$(function () {
  $('[data-bs-toggle="tooltip"]').tooltip({show: null})
})*/


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//require('/usaCitiesUniq.json');

// Eable tooltip
import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function(event) {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});
